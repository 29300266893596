import { NgModule } from "@angular/core";
import { CommonModule } from "@angular/common";
import { FormsModule } from "@angular/forms";
import { RouterModule } from "@angular/router";

import { InfiniteScrollModule } from "ngx-infinite-scroll";
import { Ng5SliderModule } from "ng5-slider";
import { LazyLoadImagesModule } from "ngx-lazy-load-images";
import { ModalModule, BsDatepickerModule, BsDropdownModule, BsDropdownConfig } from 'ngx-bootstrap';

import { TranslateModule } from "@ngx-translate/core";
import { ConnectionModule } from "../connection/connection.module";

import { SearchApartmentComponent } from "./searchApartment/searchApartment.component";
import { SearchApartmentFiltersComponent } from "./searchApartment/filters/searchApartmentFilters.component";
import { SearchProfileComponent } from "./searchProfile/searchProfile.component";
import { SearchProfileFiltersComponent } from "./searchProfile/filters/searchProfileFilters.component";
import { SearchBannerComponent } from "./searchBanner/searchBanner.component";
import { GeneralModule } from "../general/general.module";
import { SearchApartmentMap } from "./searchApartment/map/searchApartmentMap.component";

export { SearchApartmentComponent, SearchProfileComponent };

@NgModule({
  imports: [
    CommonModule,
    FormsModule,
    InfiniteScrollModule,
    RouterModule,
    TranslateModule,
    Ng5SliderModule,
    LazyLoadImagesModule,
    ConnectionModule,
    BsDatepickerModule,
    ModalModule,
    BsDropdownModule,
    GeneralModule,
  ],
  declarations: [
    SearchApartmentComponent,
    SearchApartmentFiltersComponent,
    SearchProfileComponent,
    SearchProfileFiltersComponent,
    SearchBannerComponent,
    SearchApartmentMap
  ],
  providers: [{ provide: BsDropdownConfig, useValue: { autoClose: true } }],
  exports: [SearchApartmentComponent]
})
export class SearchModule {}
