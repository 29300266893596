import { Http } from "@angular/http";
import { Injectable } from "@angular/core";
import { Utils } from 'app/utils/utils';

@Injectable()
export class SystemController {

  constructor(
    private http: Http,
    private utils: Utils,
  ) {}

  async getBranchName() {
    return this.http.get(`${this.utils.getEnvironmentValue("apiUrl")}system/info`)
      .toPromise()
      .then((response) => response.json());
  }
}
