import {
  Component,
  Input,
  OnInit,
  ViewChild,
  ElementRef,
  Renderer2,
} from "@angular/core";
import { Utils } from "../../../utils/utils";
import { Router, ActivatedRoute } from "@angular/router";
import { HttpClient } from "@angular/common/http";
import * as moment from "moment";
import * as _ from "lodash";
import { Cookie } from "ng2-cookies";
import { FormGroup, FormBuilder, Validators } from "@angular/forms";
import {
  StripeService,
  StripeCardNumberComponent,
  StripeCardCvcComponent,
  StripeCardExpiryComponent,
} from "ngx-stripe";
import {
  StripeCardElementOptions,
  StripeElementsOptions,
} from "@stripe/stripe-js";

import {
  GeneralController,
  PaymentController,
  RentRequestController,
  UserController,
  SystemController,
} from "../../../controllers/controllers.module";
import * as EmailValidator from "email-validator";
import { MAX_SHORT_STAY_DAYS } from "../../../utils/constants";

@Component({
  selector: "app-payment",
  templateUrl: "./payment.component.html",
  styleUrls: ["./payment.component.css"],
})
export class PaymentComponent implements OnInit {
  @ViewChild(StripeCardNumberComponent) card: StripeCardNumberComponent;

  requstId: string = "";
  formNotSubmitting = true;
  loaderImage = "assets/images/three-dots.svg";
  imageHost: string = this.utils.getEnvironmentValue("amazonS3Url");
  apartment: any;
  paymentData: any;
  roomRentPerMonth: number;
  totalPayment: number;
  token: any;
  name: string;
  email: string;
  country = "Canada";
  zip: string;
  roomIds: any;
  moveInDate: string;
  moveOutDate: string;
  stayInDays = 0;
  stayInMonths = 0;
  isError = false;
  errorMessage: string;
  isSuccess = false;
  successMessage: string;
  landLordReply: string;
  landLordImage: string;
  hasReply = false;
  loginModal: any;
  formSubmitted: boolean;
  customerMessage: string;
  identity: string;
  poi: string;
  otherDocument: string;
  proofOfStudy: string;
  proofOfEmployment: string;
  phone: string;
  purposeOfStay: string;
  confirmationModal: any;
  successModal: any;
  confirmationStatus: string;
  confirmationStage: string;
  countriesList: any;
  emailMask: ["/^\\w+([\\.-]?\\w+)*@\\w+([\\.-]?\\w+)*(\\.\\w{2,3})+$/"];
  emailError: string;
  isEmailError: boolean;
  locale: string;
  postalCodeMask = [
    /[a-zA-Z]/,
    /[0-9]/,
    /[a-zA-Z]/,
    " ",
    /[0-9]/,
    /[a-zA-Z]/,
    /[0-9]/,
  ];
  cardOptions: StripeCardElementOptions;
  // @ts-ignore
  elementsOptions: StripeElementsOptions;
  paymentCollectionMethod = "send_invoice";
  isCompleteUpfrontRent = false;

  stripeTest: FormGroup;
  cardIsReady = false;
  expiryIsReady = false;
  cvcIsReady = false;
  isCardComplete = false;
  isCvcComplete = false;
  isExpiryComplete = false;
  MAX_SHORT_STAY_DAYS = MAX_SHORT_STAY_DAYS;
  initials: string = "";
  agreementFirstName: string = '';
  agreementLastName: string = '';
  agreement: string = '';
  emergencyPhone: string = '';
  emergencyEmail: string = '';
  showSummary = true;

  constructor(
    private utils: Utils,
    private router: Router,
    private route: ActivatedRoute,
    private fb: FormBuilder,
    private stripeService: StripeService,
    private http: HttpClient,
    private paymentController: PaymentController,
    private rentReqController: RentRequestController,
    private el: ElementRef,
    private renderer: Renderer2,
    private genCtrl: GeneralController,
    private userCtrl: UserController,
    private sysCtrl: SystemController,
  ) {
    this.elementsOptions = {
      locale: "en",
      fonts: [
        {
          family: "Product Sans",
          src: `url('https://fonts.cdnfonts.com/s/13998/ProductSans-Regular.woff')`,
        },
      ],
    };

    this.cardOptions = {
      style: {
        base: {
          iconColor: "#666EE8",
          color: "#31325F",
          fontWeight: "300",
          fontFamily: '"Product Sans"',
          fontSize: "15px",
          "::placeholder": {
            color: "#707070",
          },
        },
      },
    };

    this.stripeTest = this.fb.group({
      name: [this.name, [Validators.required]],
      amount: this.totalPayment,
      email: [this.email, [Validators.required]],
      zip: [this.zip, [Validators.required]],
      country: [this.country, [Validators.required]],
    });
    this.utils.showOverlay();
  }

  async ngOnInit() {
    // Check if user is logged in
    if (Cookie.get("user")) {
      const storedUser = JSON.parse(Cookie.get("user"));
      try {
        const user = await this.userCtrl.verifyUser(storedUser.id);

        if (!user) {
          this.utils.resetUser();
        } else {
          this.utils.setLoggedUser(user);
          this.userCtrl.getLoginService().loginCompletedNotifyOther();
        }
      } catch (err) {
        this.utils.resetUser();
      }
    }
    if (!this.utils.getLoggedUser()) {
      this.router.navigate(["/" + this.utils.getCurrentLang() + "/login"], {
        queryParams: {
          redirect:
            this.utils.getCurrentLang() + "/payment?request=" + this.requstId,
        },
      });
      this.genCtrl.triggerAlertError(
        this.utils.getTranslator().instant("Please login and then retry")
      );
      return;
    }

    this.email = this.utils.getLoggedUser().email;
    await this.setData();
    this.countriesList = await this.paymentController.getCountries();
    this.showPage();
    this.utils.executeBrowserOnly(() => {
      window["gtag"]("event", "Booking_Enter_payment_page", {
        event_category: "Booking_Enter_payment_page",
        event_label: "Booking_Enter_payment_page",
      });
    });
  }

  showPage() {
    if (this.cardIsReady && this.expiryIsReady && this.cvcIsReady) {
      this.utils.hideOverlay();
    } else {
      this.utils.showOverlay();
    }
  }

  setCard(isComplete) {
    this.isCardComplete = isComplete;
  }

  setExpiry(isComplete) {
    this.isExpiryComplete = isComplete;
  }

  setCvc(isComplete) {
    this.isCvcComplete = isComplete;
  }

  loadCard() {
    console.log(`Card component is ready.`);
    this.cardIsReady = true;
    this.showPage();
  }

  loadExpiry() {
    console.log(`Expiry component is ready.`);
    this.expiryIsReady = true;
    this.showPage();
  }

  loadCvc() {
    console.log(`Cvc component is ready.`);
    this.cvcIsReady = true;
    this.showPage();
  }

  // tslint:disable-next-line:use-life-cycle-interface
  ngAfterViewInit() {
    this.utils.executeBrowserOnly(() => {
      this.loginModal = this.el.nativeElement.querySelector("div.modal-login");
    });
  }

  setEmail(email) {
    if (this.validEmail(email)) {
      this.isEmailError = false;
      this.emailError = "";
      this.email = email;
    } else {
      this.isEmailError = true;
      this.emailError = "Please provide a valid email.";
    }
  }

  validEmail(email) {
    return EmailValidator.validate(email);
  }

  setName(name) {
    this.name = name;
  }

  setCountry(country) {
    this.country = country;
  }

  setZip(zip) {
    this.zip = zip;
  }

  formatDate(date) {
    return this.utils.formatDate(date);
  }

  formatCurrency(amount) {
    return this.utils.formatCurrency(amount, this.utils.getCurrentLang());
  }

  formDisabled() {
    return (
      !this.name ||
      !this.email ||
      !this.country ||
      !this.zip ||
      !this.isCardComplete ||
      !this.isExpiryComplete ||
      !this.isCvcComplete
    );
  }

  async setData() {
    const requestData = {
      data: JSON.parse(localStorage.getItem("requestData")),
    };
    if (!requestData || !(requestData as any).data) {
      this.router.navigate(["/"]);
      this.genCtrl.triggerAlertError(
        this.utils
          .getTranslator()
          .instant(JSON.parse((requestData as any).error._body).error)
      );
      return;
    }

    let bookingData: any = requestData.data;
    bookingData.apartment = requestData.data.apartment;
    bookingData.roomRent = requestData.data.paymentData.roomRent;
    bookingData.roomRentPerMonth = requestData.data.originalRent;
    bookingData.stay = {
      days: requestData.data.stayInDays,
      months: requestData.data.stayInMonths,
    };
    bookingData.tax = requestData.data.paymentData.tax;
    bookingData.totalAmountPayable =
      requestData.data.paymentData.totalAmountPayable;
    bookingData.totalRent = requestData.data.paymentData.totalRent;
    bookingData.user = this.utils.getLoggedUser();
    bookingData.utilityFee = requestData.data.paymentData.utilityFee;
    bookingData.utilityRate = requestData.data.paymentData.utilityRate;
    bookingData.message = requestData.data.message;
    bookingData.identity = requestData.data.identity;
    bookingData.poi = requestData.data.poi;
    bookingData.phone = requestData.data.phone;
    bookingData.purposeOfStay = requestData.data.purposeOfStay;
    bookingData.otherDocument = requestData.data.otherDocument;
    bookingData.pos = requestData.data.pos;
    bookingData.poe = requestData.data.poe;
    bookingData.initials = requestData.data.initials;
    bookingData.agreementFirstName = requestData.data.agreementFirstName;
    bookingData.agreementLastName = requestData.data.agreementLastName;
    bookingData.agreement = requestData.data.agreement;
    bookingData.emergencyPhone = requestData.data.emergencyPhone;
    bookingData.emergencyEmail = requestData.data.emergencyEmail;

    this.setBookingData(bookingData);
  }

  navigateToNotFound() {
    this.router.navigate(["404"], { skipLocationChange: false });
  }

  setBookingData(bookingData) {
    this.apartment = bookingData.apartment;
    this.paymentData = {
      total: bookingData.totalRent,
      tax: bookingData.tax,
      utilityFee: bookingData.utilityFee,
      utilityRate: bookingData.utilityRate,
      roomRent: bookingData.roomRent,
      totalAmountPayable: bookingData.totalAmountPayable,
      roomRentPerMonth: bookingData.roomRentPerMonth,
      totalRentOfStay: this.calculateTotalRentOfStay(
        bookingData.moveInDate,
        bookingData.moveOutDate,
        bookingData.stay.months,
        bookingData.roomRentPerMonth
      ),
    };
    this.roomIds = bookingData.roomIds;
    this.moveInDate = bookingData.moveInDate;
    this.moveOutDate = bookingData.moveOutDate;
    this.stayInDays = bookingData.stay.days;
    this.stayInMonths = bookingData.stay.months;
    this.roomRentPerMonth = bookingData.roomRentPerMonth;
    this.customerMessage = bookingData.message;
    this.identity = bookingData.identity;
    this.poi = bookingData.poi;
    this.phone = bookingData.phone;
    this.purposeOfStay = bookingData.purposeOfStay;
    this.otherDocument = bookingData.otherDocument;
    this.proofOfStudy = bookingData.pos;
    this.proofOfEmployment = bookingData.poe;
    this.initials = bookingData.initials;
    this.agreementFirstName = bookingData.agreementFirstName;
    this.agreementLastName = bookingData.agreementLastName;
    this.agreement = bookingData.agreement;
    this.emergencyPhone = bookingData.emergencyPhone;
    this.emergencyEmail = bookingData.emergencyEmail;
  }

  async pay() {
    if (!this.utils.getLoggedUser()) {
      this.showModal(this.loginModal);
      return 0;
    }
    this.utils.showOverlay();
    try {
      await this.stripeService
        .createToken(this.card.element, { name: this.name })
        .subscribe(async (result) => {
          if (result.token) {
            this.token = result.token;
            this.saveBooking()
              .then(() => {
                localStorage.setItem("bookingData", null);
                localStorage.setItem("applicationData", null);
                localStorage.setItem("requestData", null);
                this.formSubmitted = true;
                this.setModalForSuccess();
                this.utils.hideOverlay();
                window["gtag"]("event", "Booking_Payment_success", {
                  event_category: "Booking_Payment_success",
                  event_label: "Booking_Payment_success",
                });
              })
              .catch((err) => {
                this.utils.hideOverlay();
                if (err && err.json()["error"]) {
                  this.setError(err.json()["error"]);
                  window["gtag"]("event", "Booking_Payment_Error", {
                    event_category: "Booking_Payment_Error",
                    event_label: JSON.stringify({
                      userId: this.utils.getLoggedUser().id,
                      message: err.json()["error"],
                    }),
                  });
                } else {
                  const message = this.utils
                    .getTranslator()
                    .instant(
                      "The payment has been declined. Please try a different card, or contact your bank for more information."
                    );
                  this.setError(message);
                  window["gtag"]("event", "Booking_Payment_Error", {
                    event_category: "Booking_Payment_Error",
                    event_label: JSON.stringify({
                      userId: this.utils.getLoggedUser().id,
                      message:
                        "The payment has been declined. Please try a different card, or contact your bank for more information.",
                    }),
                  });
                }
              });
          } else {
            this.utils.hideOverlay();
            if (result.error) {
              if (result.error.type === "card_error") {
                this.setError(result.error.message);
              } else {
                const message = this.utils
                  .getTranslator()
                  .instant(
                    "The payment has been declined. Please try a different card, or contact your bank for more information."
                  );
                this.setError(message);
              }
              window["gtag"]("event", "Booking_Payment_Error", {
                event_category: "Booking_Payment_Error",
                event_label: JSON.stringify({
                  userId: this.utils.getLoggedUser().id,
                  message: result.error.message,
                  code: result.error.code,
                  type: result.error.type,
                  param: result.error.param,
                  decline_code: result.error.decline_code,
                  charge: result.error.charge,
                }),
              });
            } else {
              const message = this.utils
                .getTranslator()
                .instant(
                  "The payment has been declined. Please try a different card, or contact your bank for more information."
                );
              this.setError(message);
            }
          }
        });
    } catch (error) {
      this.setError("There is something wrong at our end.");
      this.utils.hideOverlay();
    }
  }

  async saveBooking() {
    const data = this.prepareDataForRequest();
    await this.paymentController.saveBooking(data);
  }

  prepareDataForRequest() {
    // Convert moveInDate and moveOutDate to local time
    const moveInDate = moment(this.moveInDate).format("YYYY-MM-DD");
    const moveOutDate = moment(this.moveOutDate).format("YYYY-MM-DD");

    const payload = { token: this.token };
    payload["apartmentId"] = this.apartment._id;
    payload["roomIds"] = this.roomIds;
    payload["moveInDate"] = moveInDate;
    payload["moveOutDate"] = moveOutDate;
    payload["type"] = "rentRequest";
    payload["purposeOfStay"] = this.purposeOfStay;
    payload["message"] = this.customerMessage;
    payload["identity"] = this.identity;
    payload["poi"] = this.poi;
    payload["otherDocument"] = this.otherDocument;
    payload["pos"] = this.proofOfStudy;
    payload["poe"] = this.proofOfEmployment;
    payload["locale"] = this.utils.getCurrentLang();
    payload["rentType"] = this.apartment.rentType || "Monthly";
    payload["billingEmail"] = this.email;
    payload["billingName"] = this.name;
    payload["billingCountry"] = this.country;
    payload["billingZip"] = this.zip;
    payload["phone"] = this.phone;
    payload["paymentCollectionMethod"] = this.paymentCollectionMethod;
    payload["isCompleteUpfrontRent"] = this.isCompleteUpfrontRent;
    payload["initials"] = this.initials;
    payload['agreementFirstName'] = this.agreementFirstName;
    payload['agreementLastName'] = this.agreementLastName;
    payload['agreement'] = this.agreement;
    payload['emergencyPhone'] = this.emergencyPhone;
    payload['emergencyEmail'] = this.emergencyEmail;
    return payload;
  }

  showLoader() {
    const image = document.getElementById("loader-image");
    image.style.display = "inline-block";
    image.style.visibility = "visible";
    this.formNotSubmitting = false;
  }

  hideLoader() {
    const image = document.getElementById("loader-image");
    image.style.display = "none";
    image.style.visibility = "hidden";
    this.formNotSubmitting = true;
  }

  prepareApartmentImage(filename) {
    return `${this.imageHost}apartment/${filename}`;
  }

  setError(errorMessage) {
    this.removeSuccess();
    this.isError = true;
    this.errorMessage = errorMessage;
  }

  removeError() {
    this.isError = false;
    this.errorMessage = "";
  }

  setSuccess(successMessage) {
    this.removeError();
    this.isSuccess = true;
    this.successMessage = successMessage;
  }

  removeSuccess() {
    this.isSuccess = false;
    this.successMessage = "";
  }

  setTimeOutAndRedirect(redirectionUrl) {
    return setTimeout(() => {
      return this.router.navigate([redirectionUrl]);
    }, 3000);
  }

  redirect(redirectionUrl) {
    return this.router.navigate([redirectionUrl]);
  }

  userHasLoggedIn() {
    return !_.isEmpty(this.utils.getLoggedUser());
  }

  showModal(modal) {
    this.genCtrl.showModal(this.renderer, modal);
  }

  goToDetailsScreen() {
    this.router.navigate([
      `${this.utils.getCurrentLang()}/myprofile/applications`,
    ]);
  }

  goToApplicationSummary() {
    this.router.navigate([
      `${this.utils.getCurrentLang()}/application-summary`,
    ], {
      queryParams: { 
        step: 2,
        pos: this.purposeOfStay,
      }
    });
  }

  setModalForSuccess() {
    this.confirmationStatus = "success";
    this.confirmationStage = "payment";
    this.setConfirmationModal();
    this.showModal(this.confirmationModal);
  }

  cancel() {
    this.confirmationStage = "payment";
    this.confirmationStatus = "cancel";
    this.setConfirmationModal();
    this.showModal(this.confirmationModal);
  }

  setConfirmationModal() {
    this.utils.executeBrowserOnly(() => {
      this.utils.setTooltips();
      this.utils.setToggle();
      this.confirmationModal = this.el.nativeElement.querySelector(
        "div.modal-confirmation"
      );
    });
  }

  receiveConfirmation(yes) {
    if (yes === "true") {
      this.goToDetailsScreen();
    }
  }

  getPriceHeading() {
    switch (this.apartment.rentType) {
      case "Daily":
        return "Daily price";
      case "Weekly":
        return "Weekly price";
      case "Monthly":
        return "Monthly price";
      default:
        return "Monthly price";
    }
  }

  findRoomInfo(id: string) {
    for (let i = 0; i < this.apartment.rooms.length; ++i) {
      if (this.apartment.rooms[i]._id == id) {
        return {
          room: this.apartment.rooms[i],
          index: i,
        };
      }
    }
  }

  generateBookedRoomNumberString() {
    let roominfos = [];
    for (let id of this.roomIds) {
      roominfos.push(this.findRoomInfo(id));
    }
    let res = "";
    for (let i = 0; i < roominfos.length; ++i) {
      res +=
        this.utils.getTranslator().instant("Room") + (roominfos[i].index + 1);
      if (i < roominfos.length - 1) {
        res += ", ";
      }
    }
    return res;
  }

  setPaymentCollectionMethod(paymentCollectionMethod) {
    this.paymentCollectionMethod = paymentCollectionMethod;
  }

  calculateUsedDaysAndPerDayRent(moveInDate, moveOutDate, rent) {
    const dayInIncompleteMonthStart = moment(moveInDate).daysInMonth();
    const dayInIncompleteMonthEnd = moment(moveOutDate).daysInMonth();

    return {
      perDayRent: {
        start: rent / dayInIncompleteMonthStart,
        end: rent / dayInIncompleteMonthEnd,
      },
      usedDay: {
        start: dayInIncompleteMonthStart - moment(moveInDate).date() + 1,
        end: moment(moveOutDate).date(),
      },
    };
  }

  calculateTotalRentOfStay(
    moveInDate,
    moveOutDate,
    stayInMonths,
    roomRentPerMonth
  ) {
    const isMoveInDateOnFirst = moment(moveInDate).date() === 1;
    const isMoveOutDateOnLast =
      moment(moveOutDate).date() === moment(moveOutDate).endOf("M").date();
    const isAllCompleteMonths = isMoveInDateOnFirst && isMoveOutDateOnLast;
    const fullMonthsRent =
      roomRentPerMonth *
      Math.ceil(
        stayInMonths -
          (isAllCompleteMonths
            ? 0
            : isMoveInDateOnFirst && isMoveOutDateOnLast
            ? 2
            : 1)
      );

    const { perDayRent, usedDay } = this.calculateUsedDaysAndPerDayRent(
      moveInDate,
      moveOutDate,
      roomRentPerMonth
    );
    const rentOfStartUsedDays = _.round(perDayRent.start * usedDay.start);
    const rentOfEndUsedDays = _.round(perDayRent.end * usedDay.end);

    const numberOfDaysInFirstMonth = moment(moveInDate).daysInMonth();
    const numberOfDaysInSecondMonth = moment(moveInDate)
      .add("1", "M")
      .daysInMonth();
    const totalTax = isMoveInDateOnFirst
      ? numberOfDaysInFirstMonth
      : numberOfDaysInSecondMonth + usedDay.start;

    let rent = 0;

    if (isAllCompleteMonths) {
      rent = fullMonthsRent + totalTax;
    } else if (!isMoveInDateOnFirst && isMoveOutDateOnLast) {
      rent = rentOfStartUsedDays + fullMonthsRent + totalTax;
    } else if (isMoveInDateOnFirst && !isMoveOutDateOnLast) {
      rent = rentOfEndUsedDays + fullMonthsRent + totalTax;
    } else {
      rent =
        fullMonthsRent + rentOfEndUsedDays + rentOfStartUsedDays + totalTax;
    }

    return rent;
  }
}
