import {Component, ElementRef, OnInit, Renderer2, ViewChild, ViewEncapsulation} from '@angular/core';
import {Utils} from '../../../utils/utils';
import {BsLocaleService} from 'ngx-bootstrap/datepicker';
import {ActivatedRoute, Router} from '@angular/router';
import {RentRequestController, UserController, GeneralController, SystemController} from '../../../controllers/controllers.module';
import {FileUploader} from 'ng2-file-upload';
import {Http} from '@angular/http';
import * as _ from 'lodash'
import {PhoneVerifyCodeComponent} from '../../profile/phoneverifycode/phoneVerifyCode.component';
import {defineLocale} from 'ngx-bootstrap/chronos';
import {frLocale} from 'ngx-bootstrap/locale';
import {MAX_SHORT_STAY_DAYS, MIN_MESSAGE_WORDS} from '../../../utils/constants';
import { UserUtils } from 'app/utils/user';
import { TemplateController } from 'app/controllers/template.controller';
import { DomSanitizer } from '@angular/platform-browser';	
import * as moment from 'moment';
import * as striptags from 'striptags';

@Component({
    selector: 'app-application-summary',
    templateUrl: './application-summary.component.html',
    styleUrls: ['./application-summary.component.css'],
    encapsulation: ViewEncapsulation.None
})
export class ApplicationSummaryComponent implements OnInit {
    timeOut = 30
    smsSent: boolean
    phoneVerificationButtonDisabled = false
    phoneVerified = false
    checkPhoneTimerSet = false
    phoneInvalid = false
    prevCheckedPhone: string
    normalizedPhone: string
    phone: string
    selectedCountry: number = 0;

    checkEmergencyPhoneTimerSet = false
    emergencyPhoneInvalid = false
    emergencyPhone: string
    prevCheckedEmergencyPhone: string
    normalizedEmergencyPhone: string
    selectedEmergencyPhoneCountry: number = 0;
    emergencyEmail: string
    emergencyEmailInvalid = false

    isError: boolean
    errorMessage: string
    isSuccess: boolean
    successMessage: string
    phoneVerifyModal: any
    loaderImage = 'assets/images/three-dots.svg'
    identity: string
    identityUploading: boolean
    identityUploaded: boolean
    identityOriginalName: string
    poi: string
    poiUploading: boolean
    poiUploaded: boolean
    poiOriginalName: string
    poiRequired: boolean
    apartment: any
    paymentData: any
    roomIds: any
    moveInDate: string
    moveOutDate: string
    stayInDays = 0
    stayInMonths = 0
    roomRentPerMonth = 0
    message: string
    messagePlaceHolder: string
    purposesOfStay: any
    purposeOfStay: string
    countryCodes: any
    mobileView = false
    buttonDisabled = true
    currentLang: string
    extraValidationRequired: boolean
    confirmationModal: any
    otherDocumentRequired: boolean
    otherDocument: string
    otherDocumentUploading: boolean
    otherDocumentUploaded: boolean
    otherDocumentOriginalName: string

    proofOfStudy: string;
    proofOfStudyUploading: boolean;
    proofOfStudyUploaded: boolean;
    proofOfStudyOriginalName: string;

    proofOfEmployment: string;
    proofOfEmploymentUploading: boolean;
    proofOfEmploymentUploaded: boolean;
    proofOfEmploymentOriginalName: string;

    showPoi = false
    confirmStage = 'applicationSummary'
    confirmStatus = 'cancel'
    messageWords = 0
    MIN_MESSAGE_WORDS = MIN_MESSAGE_WORDS;
    MAX_SHORT_STAY_DAYS = MAX_SHORT_STAY_DAYS;

    step: number = 1;
    agreementConfirmed: boolean = false;
    agreementTemplate;
    agreementText: string = "";
    templateTitle: string = "rental_agreement";
    originalAgreementText: string = "";

    identityUploader: FileUploader = new FileUploader({
        url: this.utils.getEnvironmentValue('apiUrl') + 'upload-image/identities',
        removeAfterUpload: true,
        allowedFileType: ['pdf', 'image'],
        headers: [
            {name: 'Authorization', value: this.utils.getLoginToken()},
            {name: 'authentication', value: this.userUtil.getLoggedUser().id}
        ],
    });
    poiUploader: FileUploader = new FileUploader({
        url: this.utils.getEnvironmentValue('apiUrl') + 'upload-image/proofs-of-income',
        removeAfterUpload: true,
        allowedFileType: ['pdf', 'image'],
        headers: [
            {name: 'Authorization', value: this.utils.getLoginToken()},
            {name: 'authentication', value: this.userUtil.getLoggedUser().id}
        ],
    });
    otherDocumentUploader: FileUploader = new FileUploader({
        url: this.utils.getEnvironmentValue('apiUrl') + 'upload-image/others',
        removeAfterUpload: true,
        allowedFileType: ['pdf', 'image'],
        headers: [
            {name: 'Authorization', value: this.utils.getLoginToken()},
            {name: 'authentication', value: this.userUtil.getLoggedUser().id}
        ],
    });
    proofOfStudyUploader: FileUploader = new FileUploader({
        url: this.utils.getEnvironmentValue('apiUrl') + 'upload-image/proof-of-study',
        removeAfterUpload: true,
        allowedFileType: ['pdf', 'image'],
        headers: [
            {name: 'Authorization', value: this.utils.getLoginToken()},
            {name: 'authentication', value: this.userUtil.getLoggedUser().id}
        ], 
    });
    proofOfEmploymentUploader: FileUploader = new FileUploader({
        url: this.utils.getEnvironmentValue('apiUrl') + 'upload-image/proof-of-employment',
        removeAfterUpload: true,
        allowedFileType: ['pdf', 'image'],
        headers: [
            {name: 'Authorization', value: this.utils.getLoginToken()},
            {name: 'authentication', value: this.userUtil.getLoggedUser().id}
        ],
    });
    imageHost: string = this.utils.getEnvironmentValue('amazonS3Url');
    @ViewChild('phoneVerifier') phoneVerifier: PhoneVerifyCodeComponent
    currentUser: any = {}
    constructor(
        private http: Http,
        private utils: Utils,
        private genCtrl: GeneralController,
        private localeService: BsLocaleService,
        private rentRequestController: RentRequestController,
        private renderer: Renderer2,
        private el: ElementRef,
        private router: Router,
        private userCtrl: UserController,
        private userUtil: UserUtils,
        private templateController: TemplateController,
        private sysCtrl: SystemController,
        public domSanitizer: DomSanitizer,
        private activatedRoute: ActivatedRoute
    ) {
        this.purposesOfStay = this.utils.getPurposesOfStay()
        this.identityUploader.onCompleteItem = (
            item: any,
            response: any,
        ) => {
            const uploadedFile = JSON.parse(response);
            this.identity = uploadedFile.filename
            console.log(uploadedFile.filename)
            this.identityOriginalName = item._file.name
            this.identityUploaded = true
            this.checkIfFormIsComplete()
        };
        this.poiUploader.onCompleteItem = (
            item: any,
            response: any,
        ) => {
            const uploadedFile = JSON.parse(response);
            this.poi = uploadedFile.filename
            this.poiOriginalName = item._file.name
            this.poiUploaded = true
            this.checkIfFormIsComplete()
        };
        this.otherDocumentUploader.onCompleteItem = (
            item: any,
            response: any,
        ) => {
            const uploadedFile = JSON.parse(response);
            this.otherDocument = uploadedFile.filename
            this.otherDocumentOriginalName = item._file.name
            this.otherDocumentUploaded = true
            this.checkIfFormIsComplete()
        };
        this.proofOfStudyUploader.onCompleteItem = (
            item: any,
            response: any,
        ) => {
            const uploadedFile = JSON.parse(response);
            this.proofOfStudy = uploadedFile.filename
            this.proofOfStudyOriginalName = item._file.name
            this.proofOfStudyUploaded = true
            this.checkIfFormIsComplete() 
        }
        this.proofOfEmploymentUploader.onCompleteItem = (
            item: any,
            response: any,
        ) => {
            const uploadedFile = JSON.parse(response);
            this.proofOfEmployment = uploadedFile.filename
            this.proofOfEmploymentOriginalName = item._file.name
            this.proofOfEmploymentUploaded = true
            this.checkIfFormIsComplete()
        }
        
        this.identityUploader.onBeforeUploadItem = () => {
            this.identityUploading = true;
        }
        this.poiUploader.onBeforeUploadItem = () => {
            this.poiUploading = true;
        }
        this.otherDocumentUploader.onBeforeUploadItem = () => {
            this.otherDocumentUploading = true;
        }
        this.proofOfStudyUploader.onBeforeUploadItem = () => {
            this.proofOfStudyUploading = true;
        }
        this.proofOfEmploymentUploader.onBeforeUploadItem = () => {
            this.proofOfEmploymentUploading = true;
        }
        this.identityUploader.onWhenAddingFileFailed = () => {
            this.isError = true;
            this.errorMessage = 'Only images or PDF are supported'
            this.identityUploading = false;
        };       
        this.poiUploader.onWhenAddingFileFailed = () => {
            this.isError = true;
            this.errorMessage = 'Only images or PDF are supported'
        };
        this.otherDocumentUploader.onWhenAddingFileFailed = () => {
            this.isError = true;
            this.errorMessage = 'Only images or PDF are supported'
        };
        this.proofOfStudyUploader.onWhenAddingFileFailed = () => {
            this.isError = true;
            this.errorMessage = 'Only images or PDF are supported'
        }
        this.proofOfEmploymentUploader.onWhenAddingFileFailed = () => {
            this.isError = true;
            this.errorMessage = 'Only images or PDF are supported'
        }
        
    }

    ngOnInit() {
        this.utils.executeBrowserOnly(async () => {
            this.currentLang = this.utils.getCurrentLang();
            try {
                await this.getCountryCodes();
            } catch (err) {
                console.log(err);
            }
            if (this.utils.getCurrentLang() !== 'en') {
                defineLocale(this.utils.getCurrentLang(), frLocale);
                this.localeService.use(this.utils.getCurrentLang());
                window['gtag']('event', 'Booking_Enter_application_summary', {
                    'event_category': 'Booking_Enter_application_summary',
                    'event_label': 'Booking_Enter_application_summary'
                });
            }
            this.setData()
            this.activatedRoute.queryParams.subscribe((params) => {
                console.log(params)
                if ('step' in params) {
                    if (this.isStayLongTerm()) {
                       this.step = (Number(params['step']) > 1 ? 2 : 1);
                    } else {
                        this.step = 1;
                    }
                }
                if ('pos' in params) {
                    this.setPurposeOfStay(params['pos']);
                }
            })
            this.currentUser = await this.userCtrl.getUser(this.userUtil.getLoggedUser().id);
            this.currentUser.agreementFirstName = this.currentUser.first_name;
            this.currentUser.agreementLastName = this.currentUser.last_name;
            if (this.isStayLongTerm()) {
                // get templat from server
                this.agreementText = (await this.templateController.getTemplate(`${this.templateTitle}_${this.currentLang}`)).body;
                this.originalAgreementText = this.agreementText;
                this.updateAgreementText();
            }
            this.extraValidationRequired = this.isStayLongTerm()
            this.getPhoneVerifyStatus();
            this.getPrevUsedBookingIntro();
            this.utils.hideOverlay();

            if (this.currentUser.identity && this.currentUser.identity.length > 0) {
                this.identity = this.currentUser.identity[this.currentUser.identity.length - 1].filename;
                this.identityOriginalName = this.identity;
                this.identityUploaded = true;
            }
            if (this.currentUser.poi && this.currentUser.poi.length > 0) {
                this.poi = this.currentUser.poi[this.currentUser.poi.length - 1].filename;
                this.poiOriginalName = this.poi;
                this.poiUploaded = true;
            }
            if (this.currentUser.otherDocument && this.currentUser.otherDocument.length > 0) {
                this.otherDocument = this.currentUser.otherDocument[this.currentUser.otherDocument.length - 1].filename;
                this.otherDocumentUploaded = true;
                this.otherDocumentOriginalName = this.otherDocument;
            }
            if (this.currentUser.pos && this.currentUser.pos.length > 0) {
                this.proofOfStudy = this.currentUser.pos[this.currentUser.pos.length - 1].filename;
                this.proofOfStudyUploaded = true;
                this.proofOfStudyOriginalName = this.proofOfStudy; 
            }
            if (this.currentUser.poe && this.currentUser.poe.length > 0) {
                this.proofOfEmployment = this.currentUser.poe[this.currentUser.poe.length - 1].filename;
                this.proofOfEmploymentUploaded = true;
                this.proofOfEmploymentOriginalName = this.proofOfEmployment;
            }
        })
    }

    getPhoneVerifyStatus() {
        this.userCtrl.isPhoneVerified()
            .then(async (res) => {
                if (res.phone && res.verified && res.verified === true) {
                    this.phoneVerified = true;
                    this.phoneInvalid = false;
                    this.phone = res.phone;
                } else {
                    let loggedUser = this.utils.getLoggedUser();
                    let user = await this.userCtrl.getUser(loggedUser.id);
                    this.phoneVerified = false;
                    this.phone = user.unverified_phone;
                }
                this.matchCountryCodeByPhone();
            })
            .catch((err) => {
                this.phoneVerified = false;
                console.log(err);
            })
    }

    getPrevUsedBookingIntro() {
        if (this.message) {
            // Do not overwrite user's message if it is already there
            return;
        }
        this.userCtrl.getPrevUsedBookingIntro()
            .then((res) => {
                if (res.message) {
                    this.message = res.message;
                    this.countWords();
                }
                if (res.emergencyPhone) {
                    this.emergencyPhone = res.emergencyPhone;
                    this.matchCountryCodeByEmergencyPhone();
                    this.checkEmergencyPhone();
                }
                if (res.emergencyEmail) {
                    this.emergencyEmail = res.emergencyEmail;
                    this.checkEmergencyEmail();
                }
            })
            .catch((err) => {
                console.log(err);
            });
    }

    matchCountryCodeByPhone() {
        this.phone = this.stripPhoneNumberCountryCode(this.phone);
    }

    stripPhoneNumberCountryCode(phone) {
        let strMatch = '';

        if (phone.charAt(0) != '+') {
            strMatch = '+';
        }

        for (let i = 0; i < Math.min(phone.length, 4); ++i) {
            strMatch += phone.charAt(i);
            for (let j = 0; j < this.countryCodes.length; ++j) {
                if (this.countryCodes[j].dial_code == strMatch) {
                    return phone.substr(i + 1);
                }
            }
        }
        return phone;
    }

    matchCountryCodeByEmergencyPhone() {
        let strMatch = '';

        if (this.emergencyPhone.charAt(0) != '+') {
            strMatch = '+';
        }

        for (let i = 0; i < Math.min(this.emergencyPhone.length, 4); ++i) {
            strMatch += this.emergencyPhone.charAt(i);
            for (let j = 0; j < this.countryCodes.length; ++j) {
                if (this.countryCodes[j].dial_code == strMatch) {
                    this.selectedEmergencyPhoneCountry = j;
                    this.emergencyPhone = this.emergencyPhone.substr(i + 1);
                    return;
                }
            }
        }
    }

    async checkPhone() {
        if (!this.phone || this.phone.length < 3) {
            this.phoneInvalid = true;
            return false;
        }
        if (!this.countryCodes || !this.countryCodes[this.selectedCountry]) {
            return false;
        }
        const numberToCheck = this.countryCodes[this.selectedCountry].dial_code + this.phone;
        if (numberToCheck !== this.prevCheckedPhone) {
            this.prevCheckedPhone = numberToCheck;
            
            try {
                const res = await this.userCtrl.checkPhoneNumber(this.countryCodes[this.selectedCountry].code, numberToCheck);
                this.phoneInvalid = !res.success;
                if (res.success) {
                    this.normalizedPhone = res.phone;
                }
            } catch (err) {
                this.phoneInvalid = true;
            }
        }
        return !this.phoneInvalid;
    }

    async checkEmergencyPhone() {
        if (!this.emergencyPhone) {
            this.emergencyPhoneInvalid = false;
            return true;
        }
        if (!this.countryCodes || !this.countryCodes[this.selectedEmergencyPhoneCountry]) {
            return false;
        }
        const numberToCheck = this.countryCodes[this.selectedEmergencyPhoneCountry].dial_code + this.emergencyPhone;
        if (numberToCheck !== this.prevCheckedEmergencyPhone) {
            this.prevCheckedEmergencyPhone = numberToCheck;
            
            try {
                const res = await this.userCtrl.checkPhoneNumber(this.countryCodes[this.selectedEmergencyPhoneCountry].code, numberToCheck);
                this.emergencyPhoneInvalid = !res.success;
                if (res.success) {
                    this.normalizedEmergencyPhone = res.phone;
                }
            } catch (err) {
                this.emergencyPhoneInvalid = true;
            }
        }
        return !this.emergencyPhoneInvalid;
    }

    checkEmergencyEmail() {
        if (!this.emergencyEmail) {
            this.emergencyEmailInvalid = false;
            return true;
        }
        const emailRegex = /^[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,}$/;
        this.emergencyEmailInvalid = !emailRegex.test(this.emergencyEmail);
        return !this.emergencyEmailInvalid;
    }

    isStayLongTerm() {
        return this.stayInDays > MAX_SHORT_STAY_DAYS
    }

    checkIfOtherDocumentRequired() {
        this.otherDocumentRequired = true
        if (this.purposeOfStay !== 'Studies') {
            this.poiRequired = true;
        } else {
            this.poiRequired = false;
        }
    }

    async getCountryCodes() {
        this.countryCodes = await this.http.get('assets/i18n/country_codes.json').toPromise().then((response) => {
            return response.json();
        }
        ).catch((err) => {
            console.log(err);
        });

    }

    userHasLoggedIn() {
        return !_.isEmpty(this.utils.getLoggedUser())
    }

    ngAfterViewInit() {
        this.utils.executeBrowserOnly(() => {
            this.utils.setTooltips();
            this.utils.setToggle();
            this.phoneVerifyModal = this.el.nativeElement.querySelector(
                'div.modal-phone-verify-code'
            );
        });
    }

    setData() {
        const bookingData = JSON.parse(localStorage.getItem('bookingData'))
        this.setBookingData(bookingData)

        // This data is stored only when the user has finished the summary page
        // So when user go to the payment page and go back, this data will be set
        // Read user's data from it
        const requestData = JSON.parse(localStorage.getItem('requestData'))
        if (requestData) {
            this.setRequestedData(requestData)
        }
    }

    checkIfFormIsComplete() {
        if (!this.formNotValid()) {
            this.buttonDisabled = false
        }
    }

    setBookingData(bookingData) {
        if (_.isEmpty(bookingData)) {
            this.router.navigate(['/'])
        }
        this.apartment = bookingData.apartment
        this.paymentData = {
            refundableDeposit: bookingData.refundableDeposit,
            total: bookingData.totalRent,
            tax: bookingData.tax,
            utilityFee: bookingData.utilityFee,
            utilityRate: bookingData.utilityRate,
            roomRent: bookingData.roomRent,
            totalAmountPayable: bookingData.totalAmountPayable,
            roomRentPerMonth: bookingData.roomRentPerMonth,
        }
        this.roomIds = bookingData.roomIds
        this.moveInDate = bookingData.moveInDate
        this.moveOutDate = bookingData.moveOutDate
        this.stayInDays = bookingData.stay.days
        this.stayInMonths = bookingData.stay.months
        this.roomRentPerMonth = bookingData.roomRentPerMonth
    }

    setRequestedData(requestData) {
        this.setMessage(requestData.message || '')
        this.emergencyEmail = requestData.emergencyEmail
        this.emergencyPhone = this.stripPhoneNumberCountryCode(requestData.emergencyPhone)
    }

    stayDuration() {
        return `${this.formatDate(this.moveOutDate)} (${this.utils.formatDuration(this.stayInDays, this.stayInMonths)})`
    }

    formatAmount(number) {
        return this.utils.formatCurrency(number, this.utils.getCurrentLang())
    }

    markPhoneVerified(isPhoneVerified) {
        if (isPhoneVerified) {
            this.phoneVerified = true
            this.phoneInvalid = false
            this.checkIfFormIsComplete()
            window['gtag']('event', 'Booking_Phone_verified_app_summary', {
                'event_category': 'Booking_Phone_verified_app_summary',
                'event_label': 'Booking_Phone_verified_app_summary'
            });
        }
        else {
            window['gtag']('event', 'Booking_Phone_not_verified_app_summary', {
                'event_category': 'Booking_Phone_not_verified_app_summary',
                'event_label': 'Booking_Phone_not_verified_app_summary'
            });
        }
    }

    showModal(modal) {
        console.log(modal)
        this.genCtrl.showModal(this.renderer, modal);
    }

    getSecureDoc(fileName, folder){
        this.userCtrl.getSecureDocs(folder,fileName).then((secureDoc) => {
            window.open(secureDoc.url,'_blank');
        });
    }

    prepareImage(filename, folder) {
        return `${this.imageHost + folder}/${filename}`
    }

    formatDate(date) {
        if (this.currentLang === 'fr') {
            return moment(date).lang(this.currentLang).format('DD MMMM, YYYY');
        } else {
            return moment(date).lang(this.currentLang).format('MMMM DD, YYYY');
        }
    }

    async uploadIdentity() {
        try {
            this.identityUploader.uploadAll();
            window['gtag']('event', 'Booking_Uploaded_ID_app_summary', {
                'event_category': 'Booking_Uploaded_ID_app_summary',
                'event_label': 'Booking_Uploaded_ID_app_summary'
            });
        } catch (error) {
            console.log('Errors occurred.')
            console.error(error)
        }
    }

    uploadPoi() {
        this.poiUploader.uploadAll();
        window['gtag']('event', 'Booking_Uploaded_POI_app_summary', {
            'event_category': 'Booking_Uploaded_POI_app_summary',
            'event_label': 'Booking_Uploaded_POI_app_summary'
        });
    }

    uploadOtherDocument() {
        this.otherDocumentUploader.uploadAll()
        window['gtag']('event', 'Booking_Uploaded_other_app_summary', {
            'event_category': 'Booking_Uploaded_other_app_summary',
            'event_label': 'Booking_Uploaded_other_app_summary'
        });
    }

    uploadProofOfStudy() {
        this.proofOfStudyUploader.uploadAll()
        window['gtag']('event', 'Booking_Uploaded_other_app_summary', {
            'event_category': 'Booking_Uploaded_other_app_summary',
            'event_label': 'Booking_Uploaded_other_app_summary'
        }); 
    }

    uploadProofOfEmployment() {
        this.proofOfEmploymentUploader.uploadAll()
        window['gtag']('event', 'Booking_Uploaded_other_app_summary', {
            'event_category': 'Booking_Uploaded_other_app_summary',
            'event_label': 'Booking_Uploaded_other_app_summary'
        });
    }

    goToDetailsScreen() {
        this.router.navigate([`${this.utils.getCurrentLang()}/apartments/${this.apartment._id}`], { queryParams: {
            moveInDate: moment(this.moveInDate).toDate().getTime(),
            moveOutDate: moment(this.moveOutDate).toDate().getTime(),
            rooms: JSON.stringify(this.roomIds),
        }});
    }

    deleteIdentity() {
        this.identityUploading = false
        this.identityUploaded = false
    }

    deletePoi() {
        this.poiUploading = false
        this.poiUploaded = false
    }

    deleteOtherDocument() {
        this.otherDocumentUploading = false
        this.otherDocumentUploaded = false
    }

    deleteProofOfStudy() {
        this.proofOfStudyUploading = false;
        this.proofOfStudyUploaded = false;
    }

    deleteProofOfEmployment() {
        this.proofOfEmploymentUploading = false;
        this.proofOfEmploymentUploaded = false;
    }

    async sendRentRequest() {
        try {
            if (!this.countryCodes || !this.countryCodes[this.selectedCountry]) {
                return;
            }

            // Save user's unverified phone number
            let loggedUser = this.utils.getLoggedUser();
            let user = await this.userCtrl.getUser(loggedUser.id);
            user.unverified_phone = this.countryCodes[this.selectedCountry].dial_code + this.phone;

            const phoneValid = await this.checkPhone();
            if (!phoneValid) {
                this.genCtrl.triggerAlertError(this.utils.getTranslator().instant('Please provide a valid phone number.'));
                return;
            }

            // If emergency phone is provided, it has to be valid
            if (this.emergencyPhone) {
                const emergencyPhoneValid = await this.checkEmergencyPhone();
                if (!emergencyPhoneValid) {
                    this.genCtrl.triggerAlertError(this.utils.getTranslator().instant('Please provide a valid emergency phone number.'));
                    return;
                }
                user.emergencyPhone = this.normalizedEmergencyPhone;
            }

            // If emergency email is provided, it has to be valid
            if (this.emergencyEmail) {
                const emergencyEmailValid = this.checkEmergencyEmail();
                if (!emergencyEmailValid) {
                    this.genCtrl.triggerAlertError(this.utils.getTranslator().instant('Please provide a valid emergency email.'));
                    return;
                }
                user.emergencyEmail = this.emergencyEmail;
            }

            this.userCtrl.updateUser(loggedUser.id, JSON.parse(JSON.stringify(user)));

            localStorage.setItem('requestData', JSON.stringify({
                apartment: this.apartment,
                paymentData: {
                    roomRent: this.paymentData.roomRent,
                    tax: this.paymentData.tax,
                    totalAmountPayable: this.paymentData.totalAmountPayable,
                    totalRent: this.paymentData.total,
                    utilityFee: this.paymentData.utilityFee,
                    utilityRate: this.paymentData.utilityRate,
                },
                originalRent: this.roomRentPerMonth,
                stayInDays: this.stayInDays,
                stayInMonths: this.stayInMonths,
                message: this.message,
                identity: this.identity,
                poi: this.poi,
                pos: this.proofOfStudy,
                poe: this.proofOfEmployment,
                phone: this.countryCodes[this.selectedCountry].dial_code + this.phone,
                purposeOfStay: this.purposeOfStay,
                otherDocument: this.otherDocument,
                roomIds: this.roomIds,
                moveInDate: this.moveInDate,
                moveOutDate: this.moveOutDate,
                agreementFirstName: this.currentUser.agreementFirstName,
                agreementLastName: this.currentUser.agreementLastName,
                agreement: this.agreementText,
                emergencyPhone: this.countryCodes[this.selectedEmergencyPhoneCountry].dial_code + this.emergencyPhone,
                emergencyEmail: this.emergencyEmail,
            }));
            this.confirmStage = 'applicationSummary';
            this.confirmStatus = 'sent';
            this.router.navigate([`${this.utils.getCurrentLang()}/payment`]);
            this.scrollToTop();
        }
        catch (err) {
            this.genCtrl.triggerAlertError(this.utils.getTranslator().instant(JSON.parse((err as any)._body).message));
            this.genCtrl.triggerAlertError(this.utils.getTranslator().instant('Failed to send request, please try again!'));
        }
    }

    formNotValid() {
        if (this.messageWords < this.MIN_MESSAGE_WORDS) {
            return true
        }
        if (this.extraValidationRequired) {
            let res: boolean = !this.identityUploaded || !this.poiUploaded || _.isEmpty(this.message) || !this.purposeOfStay || (!this.phoneVerified && !this.validatePhoneNumber());
            if (this.purposeOfStay === 'Studies' && !this.proofOfStudyUploaded) {
                res = true;
            }
            if (this.purposeOfStay === 'Job Employment' && !this.proofOfEmploymentUploaded) {
                res = true;
            }
            return res;
        } else {
            return !this.identityUploaded || _.isEmpty(this.message) || (!this.phoneVerified && !this.validatePhoneNumber())
        }

    }

    setPhone(value) {
        this.phone = value
        this.phoneVerificationButtonDisabled = false
        if (!this.checkPhoneTimerSet) {
            this.checkPhoneTimerSet = true;
            setInterval(() => {
                this.checkPhone();
            }, 1000);
        }
    }

    setEmergencyPhone(value) {
        this.emergencyPhone = value
        if (!this.checkEmergencyPhoneTimerSet) {
            this.checkEmergencyPhoneTimerSet = true;
            setInterval(() => {
                this.checkEmergencyPhone();
            }, 1000);
        }
    }

    validatePhoneNumber() {
        return this.phone != undefined &&
               this.phone.length > 3 &&
               !this.phoneInvalid &&
               /\+?[0-9]+$/.test(this.phone);
    }

    setMessage(message) {
        this.message = message
        this.countWords()
        this.checkIfFormIsComplete()
    }

    countWords() {
        this.messageWords = this.message.split(/\s+|\n/)
          .filter(function(n) { return n != '' })
          .length;
    }

    async verifyPhone() {
        if (!this.phone) {
            return false
        }
        if (!this.countryCodes || !this.countryCodes[this.selectedCountry]) {
            return false;
        }
        const countryCode = this.countryCodes[this.selectedCountry].dial_code
        await this.sendSms(countryCode + this.phone)
        this.displayVerifyCodePopup()
        this.smsSent = true
    }

    displayVerifyCodePopup() {
        const digits = (new PhoneVerifyCodeComponent(this.utils, this.el, this.renderer, this.genCtrl, this.userCtrl)).digits;
        for (let i = 0; i < digits; ++i) {
            this.el.nativeElement.querySelector(
                '#verify-digit-' + i
            ).value = '';
        }
        this.showModal(this.phoneVerifyModal);
        this.el.nativeElement.querySelector(
            '#verify-digit-0'
        ).focus();
    }

    sendSms(processedNumber) {
        this.userCtrl.sendVerifySMS(processedNumber)
            .then(() => {
                this.smsSent = true;
            })
            .catch((err) => {
                this.smsSent = false;
                this.setError('Could not send sms.')
            });
    }

    setError(errorMessage) {
        this.removeSuccess()
        this.isError = true
        this.errorMessage = errorMessage
    }

    removeError() {
        this.isError = false
        this.errorMessage = ''
    }

    setSuccess(successMessage) {
        this.removeError()
        this.isSuccess = true
        this.successMessage = successMessage
    }

    removeSuccess() {
        this.isSuccess = false
        this.successMessage = ''
    }

    cancel() {
        if (this.showAgreement()) {
            this.step = 1;
            this.scrollToTop();
        } else {
            this.goToDetailsScreen()
        }
    }

    scrollToTop() {
        window.scrollTo(0, 0);
    }

    setPurposeOfStay(value) {
        this.purposeOfStay = value
        this.checkIfOtherDocumentRequired()
        this.checkIfFormIsComplete()
        if (!_.isEmpty(this.purposeOfStay)) {
            this.showPoi = true
        } else {
            this.showPoi = false
        }
        window['gtag']('event', 'Booking_Select_purpose_of_stay', {
            'event_category': 'Booking_Select_purpose_of_stay',
            'event_label': 'Booking_Select_purpose_of_stay'
        });
    }

    setConfirmationModal() {
        this.utils.executeBrowserOnly(() => {
            this.utils.setTooltips();
            this.utils.setToggle();
            this.confirmationModal = this.el.nativeElement.querySelector(
                'div.modal-confirmation'
            );
        });
    }

    receiveConfirmation(yes) {
        if (yes === 'true') {
            this.goToDetailsScreen()
        }
    }

    getPriceHeading() {
        switch (this.apartment.rentType) {
            case 'Daily':
                return 'Daily price'
            case 'Weekly':
                return 'Weekly price'
            case 'Monthly':
                return 'Monthly price'
            default:
                return 'Monthly price'
        }
    }

    prepareFileName(fileName) {
        const extension = this.getExtension(fileName)
        const chunkedFile = fileName.split('')
        if (chunkedFile.length > 10) {
            const newName = []
            for (let i = 0; i < 4; i++) {
                newName.push(chunkedFile[i])
            }
            return newName.join('') + '...' + extension

        } else {
            return fileName
        }
    }

    getExtension(fileName) {
        const chunked = fileName.split('.')
        return chunked[1]
    }

    confirmVerification(): void {
        this.scrollToTop();
        if (this.isStayLongTerm()) {
            this.step = 2;
        }
        else {
            this.sendRentRequest();
        }
    }

    isAgreementConfirmed(): boolean {
        return this.agreementConfirmed && this.currentUser.agreementFirstName && this.currentUser.agreementLastName;
    }

    showVerification(): boolean {
        return this.step === 1;
    }

            showAgreement(): boolean {
        return this.step === 2 && this.isStayLongTerm();
    }

    updateAgreementText(): void {
        const apartmentType = ((apartment) => {
            if (apartment.isStudio) return 's';
            if (apartment.wholeApartment) return 'w';
            return 'c';
        })(this.apartment);
        let endingS = this.roomIds.length > 1 ? 's' : '';
        let numberString = {
            'en': [
                "", "one", 'two', 'three', 'four', 'five', 'six', 'seven', 'eight', 'nine', 'ten',
                'eleven', 'twelve'
            ],
            'fr': [
                "", `d'une`, "de deux", "de trois", "de quatre", "de cinq", "de six",
                "de sept", "de huit", "de neuf", "de dix", "de onze", "de douze"
            ]
        };
        const roomStringDecider = (t) => {
            if (t === 's') {
                return {
                    'en': 'a furnished studio',
                    'fr': `d'un (1) studio meublé`
                }
            }
            if (t === 'w') {
                return {
                    'en': 'a furnished whole apartment',
                    'fr': `d'un (1) logement entier meublé`
                }
            }
            if (t === 'c') {
                return {
                    'en': `${numberString[this.currentLang][this.roomIds.length]} (${this.roomIds.length}) furnished bedroom${endingS}`,
                    'fr': `${numberString[this.currentLang][this.roomIds.length]} (${this.roomIds.length}) chambre${endingS} meublée${endingS}` 
                }
            }
        }
        const roomString = {
            'en': roomStringDecider(apartmentType)['en'],
            'fr': roomStringDecider(apartmentType)['fr']
        }
        let agreement = this.originalAgreementText
                        .replace("%%%NAME%%%", `${striptags(this.currentUser.agreementFirstName)} ${striptags(this.currentUser.agreementLastName)}`)
                        .replace("%%%RENT%%%", `${this.formatAmount(this.paymentData.roomRentPerMonth)}`)
                        .replace("%%%NUMBER_OF_ROOMS%%%", roomString[this.currentLang])
                        .replace("%%%ADDRESS%%%", `${this.apartment.adress.adress}`)
                        .replace("%%%START_DATE%%%", `${this.formatDate(this.moveInDate)}`)
                        .replace("%%%END_DATE%%%", `${this.formatDate(this.moveOutDate)}`)

        if (apartmentType === 'w' || apartmentType === 's') {
            agreement = agreement.replace(', %%%ROOM_LIST%%%', '');
        } else {
            agreement = this.apartment.isStudio ? agreement.replace(', %%%ROOM_LIST%%%','') : agreement.replace("%%%ROOM_LIST%%%", `${this.currentLang === 'fr' ? 'chambre' : 'room'}${endingS} #${this.roomIds.map((roomId)=> {
                for (let i = 0; i < this.apartment.rooms.length; ++i) {
                    if (roomId === this.apartment.rooms[i]._id) return i + 1;
                }
                return -1;
            }).join(', ')}`)
        }
        this.agreementText = agreement;
        this.agreementTemplate = this.domSanitizer.bypassSecurityTrustHtml(agreement);
    }
}
