import { Component, OnInit, Input, OnChanges, SimpleChanges, OnDestroy } from '@angular/core';
import * as mapboxgl from 'mapbox-gl';
import { ApartmentService } from 'app/modules/services/apartmentService';

interface Apartment {
  _id: string;
  name: string;
  adress: {
    lat: number;
    lng: number;
    neighborhood: string;
  };
  photos: Array<{source: string}>;
  price?: number;
  wholeApartment: boolean;
  rooms: Array<{price: number}>;
  minprice: number;
  isHovered: boolean;
}


@Component({
  selector: 'app-mapbox',
  template: `
    <div id="map" class="map-container"></div>
  `,
  styles: [`
  .map-container {
    position: absolute;
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;
    width: 100%;
    height: 100%;
  }

    
    /* Custom Popup Styling */
    :host ::ng-deep .mapboxgl-popup {
      max-width: 300px !important;
      z-index: 3 !important;
    }

    :host ::ng-deep .mapboxgl-popup-content {
      padding: 0 !important;
      border-radius: 8px !important;
      box-shadow: 0 2px 10px rgba(0,0,0,0.1) !important;
      overflow: hidden !important;
      background: white !important;
    }

    :host ::ng-deep .mapboxgl-popup-close-button {
      padding: 5px !important;
      right: 8px !important;
      top: 5px !important;
      color: #666 !important;
      font-size: 18px !important;
      z-index: 2 !important;
      background: white !important;
      border-radius: 50% !important;
      width: 30px !important;
      height: 30px !important;
      display: flex !important;
      align-items: center !important;
      justify-content: center !important;
      line-height: 1 !important;
      border: none !important;
      cursor: pointer !important;
      box-shadow: 0 2px 4px rgba(0,0,0,0.1) !important;
      tabindex: -1 !important;
      outline: none !important;
    }

    :host ::ng-deep .mapboxgl-popup-close-button {
      content: '×' !important; 
      font-family: "Arial", sans-serif !important;  
      font-weight: 300 !important;
      font-size: 25px !important
    }

    :host ::ng-deep .mapboxgl-popup-close-button:hover {
      background: #f5f5f5 !important;
      color: #333 !important;
    }

    :host ::ng-deep .mapboxgl-popup-tip {
      display: none !important;
    }

    /* Price Marker Styling */
    .price-marker {
      cursor: pointer;
      background-color: white;
      border-radius: 25px;
      padding: 6px 12px;
      font-size: 13px;
      font-weight: 500;
      box-shadow: 0 2px 4px rgba(0,0,0,0.15);
      transition: all 0.2s ease;
    }

    .price-marker:hover,
    .price-marker.active {
      background-color: #ff7a59;
      color: white;
      transform: scale(1.05);
    }

    
    @media (max-width: 768px) {
      .map-container {
        width: 100vw !important;
        left: 50% !important;
        margin-left: -50vw !important;
      }
    }
  `]
})

export class SearchApartmentMap implements OnInit, OnChanges, OnDestroy {
  @Input() apartments: Apartment[] = [];
  @Input() imageHost: string = '';
  @Input() isVisible: boolean;
  
  map: mapboxgl.Map;
  markers: mapboxgl.Marker[] = [];
  markerMap: Map<string, { marker: mapboxgl.Marker, element: HTMLDivElement }> = new Map();
  
  constructor(private apartmentService: ApartmentService) {
    (mapboxgl as any).accessToken = 'pk.eyJ1IjoicWltY2lzIiwiYSI6ImNtNTE3cXI4MjFxY28ybG9kbWh6dG5leHkifQ.xZWNLFNFjy7WyZZ_bPjrvg';
  }

ngOnInit() {
    this.initializeMap();
    
    this.apartmentService.apartmentHoverState$.subscribe(({ id, isHovered }) => {
        console.log('Received hover state change:', { id, isHovered });
        const markerInfo = this.markerMap.get(id);
        if (markerInfo) {
            this.updateMarkerStyle(markerInfo.element, isHovered);
        }
    });
}
  
  ngOnChanges(changes: SimpleChanges) {
    if (changes['apartments'] && this.map) {
      this.updateMarkers();
    }
    
    if (changes['isVisible'] && this.map) {
      setTimeout(() => this.map.resize(), 100);
    }
  }

  private initializeMap() {
    this.map = new mapboxgl.Map({
      container: 'map',
      style: 'mapbox://styles/mapbox/streets-v12',
      center: [-73.5673, 45.5017], 
      zoom: 12,
      attributionControl: false,
      fitBoundsOptions: {
        padding: { top: 10, bottom: 10, left: 10, right: 10 }
      }
    });
    
    this.map.addControl(new mapboxgl.AttributionControl(), 'top-left');
    this.map.addControl(new mapboxgl.NavigationControl());
   
    //on load resize to markers
    this.map.on('load', () => {
      this.map.resize();
      if (this.apartments && this.apartments.length) {
        this.updateMarkers();
      }
    });
  }

  private getPopupContent(apartment: Apartment): string {
    //  get photo URL
    const photoUrl = apartment.photos && apartment.photos.length > 0 
      ? this.imageHost + 'apartment/' + apartment.photos[0].source
      : 'assets/images/small-no-picture.png';
    
    //  get neighborhood
    const neighborhood = apartment.adress && apartment.adress.neighborhood 
      ? apartment.adress.neighborhood 
      : '';

    // format price
    const price = `${apartment.minprice}+ CAD / Month`;

    // Create apartment URL with language prefix to match listing template structure
    const apartmentUrl = `/en/apartments/${apartment._id}`;

    return `
      <div class="popup-container" 
           onclick="window.open('${apartmentUrl}', '_blank')"
          style="cursor: pointer;">
        <div class="popup-price-header" style="
          background: linear-gradient(to top, rgba(0, 0, 0, 0.8), rgba(0, 0, 0, 0));
          color: white;
          padding: 12px 16px;
          font-size: 15px;
          font-weight: 500;
          position: absolute;
          bottom: 75px;  /* Position above the white content area */
          left: 0;
          right: 0;
          z-index: 1;
        ">
          ${price}
        </div>
        <img 
          class="popup-image" 
          src="${photoUrl}" 
          alt="${apartment.name}"
          style="width: 100%; height: 150px; object-fit: cover; display: block;"
        >
        <div class="popup-content" style="padding: 16px;">
          <div class="popup-title" style="
            font-size: 15px;
            font-weight: 600;
            margin-bottom: 4px;
            color: #333;
            line-height: 1.3;
          ">
            ${apartment.name}
          </div>
          <div class="popup-location" style="
            font-size: 13px;
            color: #666;
          ">
            ${neighborhood}
          </div>
        </div>
      </div>
    `;
  }

  private createPriceMarkerElement(apartment: Apartment): HTMLDivElement {
    const el = document.createElement('div');
    el.className = 'price-marker';

    const price = apartment.minprice;
    const currentPath = window.location.pathname;
    const isEnglish = !currentPath.startsWith('/fr');
    
    // Format price text based on language
    const priceText = isEnglish ? 
      `$${price} CAD` : 
      `${price}$ CAD`;

    el.innerHTML = priceText;

    el.style.backgroundColor = 'white';
    el.style.borderRadius = '25px';
    el.style.color = 'black';
    el.style.padding = '4px 8px';
    el.style.fontSize = '12px';
    el.style.fontWeight = 'bold';
    el.style.boxShadow = '0 2px 4px rgba(0,0,0,0.15)';
    el.style.cursor = 'pointer';

    return el;
}

private updateMarkerStyle(element: HTMLDivElement, isHovered: boolean) {
  // Use element ID to ensure we're updating the right marker
  console.log('Updating marker style:', { id: element.id, isHovered });
  
  if (!element) return;

  const styles = {
      backgroundColor: isHovered ? '#ff7a59' : 'white',
      color: isHovered ? 'white' : 'black',
      zIndex: isHovered ? '2' : '1'
  };
  
  Object.assign(element.style, styles);
}


private updateMarkers() {
  // Clear existing markers
  this.markers.forEach(marker => marker.remove());
  this.markers = [];
  this.markerMap.clear();

  if (!this.apartments) {
      return;
  }

  const bounds = new mapboxgl.LngLatBounds();
  let hasValidCoordinates = false;

  this.apartments.forEach(apartment => {
      if (apartment.adress && apartment.adress.lat && apartment.adress.lng) {
          const popup = new mapboxgl.Popup({ offset: 25 })
              .setHTML(this.getPopupContent(apartment));

          const markerElement = this.createPriceMarkerElement(apartment);
          
          // Create marker with anchor point set to bottom
          const marker = new mapboxgl.Marker({
              element: markerElement,
              anchor: 'center' // This ensures marker stays centered on its coordinates
          })
              .setLngLat([apartment.adress.lng, apartment.adress.lat])
              .setPopup(popup)
              .addTo(this.map);

          this.markerMap.set(apartment._id, { marker, element: markerElement });
          this.markers.push(marker);

          // Add hover listeners
          markerElement.addEventListener('mouseenter', () => {
              this.apartmentService.notifyHoverState(apartment._id, true);
          });
          
          markerElement.addEventListener('mouseleave', () => {
              this.apartmentService.notifyHoverState(apartment._id, false);
          });

          bounds.extend([apartment.adress.lng, apartment.adress.lat]);
          hasValidCoordinates = true;
      }
  });

  if (hasValidCoordinates) {
      this.map.fitBounds(bounds, {
          padding: { top: 50, bottom: 50, left: 50, right: 50 }
      });
  }
}

  
  ngOnDestroy() {
    this.markers.forEach(marker => marker.remove());
    this.markerMap.clear();
  }
}